import React from 'react';

import PropTypes from 'prop-types';

import Downshift from 'downshift';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormText from 'reactstrap/lib/FormText';

import styles from './styles.module.scss';

class MultiSelectDropdownAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.state = {
      isOpen: false,
      selectedItems: props.defaultSelectedItem||[],
      items: props.items || [],
      inputValue: ""
    };
  }

  componentWillMount(){
     const {
      field, layout, label, help, options,
      getOptions, onChange , ...rest
    } = this.props;
    let selectedItems = [];
    this.setState({items: this.props.items});
    this.props.items && this.props.items.forEach((item)=>{
      this.props.field && this.props.field.value && this.props.field.value.length && this.props.field.value.forEach((i)=>{
        if(i === item.id){
          selectedItems.push(item);
        }
      })
    });
    this.setState({ selectedItems: selectedItems });
  }


  componentWillReceiveProps(nextProps) {
    const {
      field, layout, label, help, options,
      getOptions, onChange, ...rest
    } = this.props;
    const { selectedItems } = this.state;
    if(nextProps.items !== this.props.items){
      const items = [
      ...nextProps.items,
      ];
      const filteredSelected = selectedItems.filter((elem) => nextProps.items.find(({ id }) => elem.id === id && elem.id !== 0));
      this.setState({selectedItems : filteredSelected})
      this.setState({items: items});

    }
  }
  
  clearSelection = () => {
    this.setState({
      selectedItems: [],
      items: this.props.items.map((x) => ({ ...x, checked: false })),
      inputValue: ""
    });

    // Notify parent that selection is cleared
    this.props.onChange([]);
  };

  handleChange(item) {
    const { selectedItems } = this.state;
    if (selectedItems.filter(i => item.id === i.id).length || (item.id == 0 && item.checked == true)) {
      this.removeItem(item);
    } else {
      this.addSelectedItem(item);
    }
  }

  handleInputChange(e) {
    const { itemToString , itemCodeToString } = this.props;
    this.setState({inputValue: e.target.value})
    var a = (e.target.value).replace(/\\/g, "|")
    const rx = new RegExp(a, 'gi');
    e.stopPropagation();
    if((e.target.value).trim() == ""){
      this.setState({items : this.props.items})
    }
    else{
      this.setState({ items: this.props.items.filter(i => i.id !== 0 && (itemToString(i).match(rx) || (itemCodeToString && itemCodeToString(i) && itemCodeToString(i).match(rx))))});
    }
   
  }

  addSelectedItem(item) {
    const { onChange , input , items } = this.props;
    const { selectedItems } = this.state;
    let obj = [...selectedItems, item];
    let updatedItems=[];
    if(item.id == 0 || ((obj.length)  == this.props.items.length)){
      updatedItems = items.map((x)=>({...x, checked: true}));
    }
    else{
      updatedItems = items.map((y)=>{
        if(obj.length && obj.find((x)=> y.id == x.id)){
          return {...y, checked: true}
        }
          return {...y, checked : false};
      })
    }
    this.props.addItem && this.props.addItem(item);
    /*this.setState({inputValue: ""});*/
/*    this.setState({items: updatedItems});*/
    this.setState(({selectedItems}) => ({
      selectedItems:updatedItems.length && updatedItems.filter((k)=> k.checked == true),
    }));
    onChange(updatedItems.length && updatedItems.filter((k)=> k.checked == true))
   /* selectTags([...this.state.selectedItems, item]);*/
  }

  /* eslint-disable */
  removeItem(item) {
    const { onChange , input , items} = this.props;
    const { selectedItems } = this.state;
    let obj = selectedItems.length && selectedItems.filter((i)=>i.id !== item.id);
    let updatedItems=[];
    if(item.id == 0 || obj.length == 0){
      updatedItems = items.map((x)=>({...x, checked: false}));
    }
    else{
      updatedItems = items.map((y)=>{
        if(obj.length && obj.find((x)=> y.id == x.id && x.id !== 0)){
          return {...y, checked: true}
        }
          return {...y ,checked : false}
      })
    }
    this.props.addItem && this.props.addItem(item);
    /*this.setState({inputValue: ""});*/
    /*this.setState({items: updatedItems});*/
    this.setState(({selectedItems}) => ({
      selectedItems:updatedItems.length && updatedItems.filter((k)=> k.checked == true),
    }));
    onChange(updatedItems.length && updatedItems.filter((k)=> k.checked == true))
   /* selectTags(this.state.selectedItems.filter(i => item.id !== i.id));*/
  }


  handleToggleMenu() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  render() {
    const {
      input,
      label,
      id,
      itemToString,
      help,
      placeholder,
      disabled,
      isDisableSelectAll,
    } = this.props;
    const { isOpen, items, selectedItems, inputValue } = this.state;
    const filteredSelected = selectedItems.filter((elem) => this.props.items.find(({ id }) => elem.id === id && elem.id !== 0));
    return (
      /* eslint-disable */
      <Downshift
        itemToString={itemToString}
        onSelect={this.handleChange}
        // onStateChange={this.handleStateChange}
        defaultSelectedItem={filteredSelected}
        selectedItem={filteredSelected}
        isOpen={isOpen}
        onOuterClick={this.handleToggleMenu}
        render={({
          getInputProps,
          getButtonProps,
          getItemProps,
          getLabelProps,
          isOpen,
          selectedItem,
          highlightedIndex,
        }) => {
          return (<div style={{ position: 'relative' }} className="custom-multi-select remove-lastpass">
            {label &&
              <label
                {...getLabelProps({
                  htmlFor: id,
                  className: 'col-form-label',
                  style: { width: '100%' },
                })}
              >
                {label}
              </label>
            }
            {/* eslint-enable */}
            <div
                className={disabled ? `${styles['multi-select']} ${styles['multi-select-disabled']} muti-select-wrap` : `${styles['multi-select']} muti-select-wrap`}
                onClick = {!disabled && this.handleToggleMenu }
            >
              <div className={styles['multi-select__content']}>
                {selectedItem && selectedItem.length > 0 &&
                  selectedItem.map(item =>
                    <label
                      key={item.id}
                      className={styles['multi-select__chip']}
                      htmlFor="chip"
                    >
                      {itemToString(item)}
                      <span
                        className="pointer"
                        role="button"
                        tabIndex="0"
                        onClick={e => {
                          e.stopPropagation();
                          this.removeItem(item);
                        }}
                      >
                        &nbsp;x
                      </span>
                    </label>,
                  )
                }
                <input
                  onClick = {(e) => {
                      if (isOpen) e.stopPropagation()}}
                  value={inputValue}
                  className= {styles['multi-select__input']}
                  onChange={this.handleInputChange}
                  placeholder= {selectedItem.length ? '' : placeholder}
                  disabled={disabled}
                />
              </div>
            </div>
            <span onClick = {!disabled && this.handleToggleMenu } style={{ position: 'absolute' , top: '12px'}} className={disabled ? `${styles['dropdown-arrow-disabled']} dropdown-arrow d-inline-block` : "dropdown-arrow d-inline-block"} />
            {isOpen ? (
              <div className={styles['multi-select__dropdown']}>
                  { (items.length && !isDisableSelectAll ?
                      <div
                        key={0}
                        {...getItemProps({item : {name : 'Select all', id : 0, checked : selectedItems.length == items.length}})}
                        style={{
                          backgroundColor:
                            highlightedIndex === 0 ? '#9CDAD7' : 'white',
                          fontWeight: filteredSelected.filter(i => 0 === i.id).length > 0
                            ? 'bold' : 'normal',
                          lineHeight: '1.1',
                          fontSize: '14px',
                          padding: '0.5rem',
                        }}
                        className="cursor-pointer"
                      >
                       <input
                        type="checkbox"
                        checked={selectedItems.length == items.length}
                        className="mr-1"
                        key={0}
                      />
                        Select  All
                      </div>
                      :
                      null) }
                {items.length && (items.length > 1 || (items.length == 1 && items[0].id !== 0))
                  ? items.map((item, index) => (
                    <div
                      key={item.id}
                      {...getItemProps({item})}
                      style={{
                        backgroundColor:
                          highlightedIndex === (isDisableSelectAll ? index : index + 1)? '#9CDAD7' : 'white',
                        fontWeight: filteredSelected.filter(i => item.id === i.id).length > 0
                          ? 'bold' : 'normal',
                        lineHeight: '1.1',
                        fontSize: '14px',
                        padding: '0.5rem',
                      }}
                      className="cursor-pointer"
                    >
                     <input
                      type="checkbox"
                      checked={selectedItems.filter((k)=> k.id == item.id).length}
                      className="mr-1"
                      key={item.id}
                    />
                      {itemToString(item)}
                    </div>
                  ))
                  : <div className={styles['multi-select__no-results']}>No Results Found</div>
                }
              </div>
            ) : null}
            <FormText color="muted">{help}</FormText>
            
          </div>);
        }}
      />
    );
  }
}

MultiSelectDropdownAtom.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  itemToString: PropTypes.func,
};

export default MultiSelectDropdownAtom;
