import React, { useState, useEffect } from "react";
import StorageGateway from "lib/storage-gateway";
import SectionLoaderAtom from "atoms/SectionLoader";
import EventEmitter from "lib/event-emitter";
import PropTypes from "prop-types";
import moment from "moment";
import * as UserDucks from "ducks/accounts/user";
import connect from "react-redux/lib/connect/connect";

const eventEmitter = new EventEmitter();

const NewDashboard = (props, context) => {
  const [isLoading, setIsLoading] = useState(true);
  // Fetch tokens and company ID from StorageGateway
  const oAuthToken = StorageGateway.get("oAuthToken");
  const company_id =
    StorageGateway.get("company_id") ?? StorageGateway.get("nre_company_id");
  const authToken = StorageGateway.get("graniteToken");
  const userId = StorageGateway.get("userId");

  let startDate;
  let endDate;

  if (
    props?.userCompany.active_payroll &&
    props?.userCompany.active_payroll.is_active
  ) {
    startDate = moment(props?.userCompany.active_payroll.start_datetime);
    endDate = moment(props?.userCompany.active_payroll.end_datetime); //.subtract(1, "days");
  }

  const eventListnerFunction = (event) => {
    const {
      router: { history },
    } = context;
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    if (data?.message === "POST_MESSAGE_PARENT") {
      history.push(data?.content?.path);
    }
  };
  useEffect(() => {
    window.addEventListener("message", eventListnerFunction, false);
    return () =>
      window.removeEventListener("message", eventListnerFunction, false);
  }, [eventEmitter]);

  return (
    <div
      style={{
        height: "78vh",
        marginRight: "-15px",
        position: "relative",
        paddingBottom: "68px",
      }}
    >
      {isLoading && <SectionLoaderAtom active />}
      <iframe
        id="nre-dashboard-iframe"
        loading="lazy"
        title="nre-iframe"
        width="100%"
        height="100%"
        frameBorder="0"
        onLoad={() => setIsLoading(false)}
        src={`${process.env.REACT_APP_WMAPPER_BASE_URL}/konect-dashboard?access_token=${authToken}&oAuthToken=${oAuthToken}&company_id=${company_id}&user_id=${userId}`}
      />
    </div>
  );
};

NewDashboard.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  userCompany: UserDucks.userCompany(state),
});

export default connect(mapStateToProps)(NewDashboard);
